@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html,
body,
#root {
  background: #f5f7fa;
  font-family: 'DM Sans';
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  position: relative;
  font-size: 16px;
}

body {
  overflow: hidden;
}

.absolute {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.scrollable {
  @extend .absolute;
  overflow: auto;
}
