.p-tree {
  background: transparent;
  color: $panelContentTextColor;
  padding: 0.5rem 1rem;
  border-radius: $borderRadius;

  .p-tree-container {
    .p-treenode {
      padding: $treeNodePadding;
      outline: 0 none;

      &:focus > .p-treenode-content {
        @include focused-listitem();
      }

      .p-treenode-content {
        border-radius: $borderRadius;
        transition: $listItemTransition;
        padding: $treeNodeContentPadding;

        .p-tree-toggler {
          margin-right: $inlineSpacing;
        }

        .p-treenode-icon {
          margin-right: $inlineSpacing;
          color: $treeNodeIconColor;
        }

        .p-checkbox {
          margin-right: $inlineSpacing;

          .p-indeterminate {
            .p-checkbox-icon {
              color: $textColor;
            }
          }
        }

        &.p-highlight {
          background: $highlightBg;
          color: $highlightTextColor;

          .p-tree-toggler,
          .p-treenode-icon {
            color: $highlightTextColor;

            &:hover {
              color: $highlightTextColor;
            }
          }
        }

        &.p-treenode-selectable:not(.p-highlight):hover {
          background: $inputListItemHoverBg;
          color: $inputListItemTextHoverColor;
        }

        &.p-treenode-dragover {
          background: $inputListItemHoverBg;
          color: $inputListItemTextHoverColor;
        }
      }
    }
  }

  .p-tree-filter-container {
    margin-bottom: $inlineSpacing;

    .p-tree-filter {
      width: 100%;
      padding-right: nth($inputPadding, 2) + $primeIconFontSize;
    }

    .p-tree-filter-icon {
      right: nth($inputPadding, 2);
      color: $inputIconColor;
    }
  }

  .p-treenode-children {
    padding: $treeNodeChildrenPadding;
  }

  .p-tree-loading-icon {
    font-size: $loadingIconFontSize;

    &.p-icon {
      width: $loadingIconFontSize;
      height: $loadingIconFontSize;
    }
  }

  .p-treenode-droppoint {
    height: $inlineSpacing;

    &.p-treenode-droppoint-active {
      background: scale-color($highlightBg, $lightness: -20%);
    }
  }
}
