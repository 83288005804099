.dataListView {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 0.25rem;
  background: #fff;
  position: relative;
  flex-grow: 1;
  .header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;

    .title {
      display: flex;
      color: #232a34;
      font-family: 'DM Sans';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
      margin: 0;
      cursor: pointer;
      height: 2.5rem;
      align-items: center;
      text-align: center;
      justify-content: center;
      // flex-grow: 1;
    }
    .active {
      background-color: #f5f7fa; /* Background color for the active title */
    }
  }
  .viewListBox {
    display: flex;
    flex-grow: 1;
    width: 100%;
    position: relative;
    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
    }
    :global {
      .p-component-overlay {
        background-color: transparent;
      }
    }
  }
  .list {
    flex-direction: column;
    .itemList {
      display: flex;
      flex-grow: 1;
      padding: 0.625rem 1rem;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      width: 100%;
      border-radius: 0.25rem;
      &.link {
        cursor: pointer;
        &:hover {
          .name {
            color: #f25844;
          }
        }
      }
      &.even {
        background: #f5f7fa;
      }
    }
    .itemList {
      display: flex;
      flex-grow: 1;
      .data {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        .item {
          display: flex;
          min-width: 2.875rem;
          align-items: center;
          gap: 0.25rem;
          color: #232a34;
          font-family: 'DM Sans';
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5rem;
          .icon {
            &.fail {
              color: #e84f40;
              &:before {
                color: #e84f40;
              }
            }
            &.error {
              color: #f2994a;
              &:before {
                color: #f2994a;
              }
            }
          }
        }
      }
      .name {
        overflow-wrap: anywhere;
      }
      .name,
      .checkName {
        display: flex;
        flex-grow: 1;
        color: #232a34;
        font-family: 'DM Sans';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        padding: 0 1rem 0 0;
        line-height: 1.125rem; /* 128.571% */
        margin: 0;
      }
      .checkName {
        font-weight: 400;
      }
    }
  }
}

.noData {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: #717e90;
  font-family: 'DM Sans';
  font-style: 'normal';
  font-weight: 400;
  line-height: 1.5rem;
}

.chevronTreeContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & .checkItemContainer {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    cursor: pointer;
  }
}

.treeWrapper {
  padding-left: 1.25rem;
  width: 100%;

  li {
    padding: 0.625rem;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    border-radius: 0.25rem;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    font-family: 'DM Sans';
    cursor: pointer;

    &:hover {
      background-color: #eaeff5;
    }
  }
}

.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

