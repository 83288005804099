.field {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: row;
  .label {
    display: flex;
    &.labelStyle {
      color: #232a34;
      text-align: right;
      font-family: 'DM Sans';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.125rem;
    }
  }
}
