.appPage {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 25px 30px;
  .header {
    display: flex;
    .left {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      width: 50%;
    }
    .right {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      align-items: center;
      width: 50%;
    }
  }
}

.titlePage {
  color: #232a34;
  font-family: 'DM Sans';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  margin: 0;
  margin-top: 0.56rem;
  margin-bottom: 2rem;
}
