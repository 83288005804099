.filterTags {
  display: inline-flex;
  align-items: flex-start;
  gap: 0.5rem;
  margin-top: 1.5rem;
  flex-wrap: wrap;

  .tag {
    display: flex;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    align-items: center;
    gap: 1rem;
    border-radius: 2.5rem;
    border: 1px solid #ccd8ea;
    background: #fff;
    .frame {
      align-items: center;
      display: inline-flex;
      flex: 0 0 auto;
      gap: 12px;
      position: relative;
      .frameBody {
        color: transparent;
        font-family: 'DM Sans';
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 24px;
        position: relative;
        white-space: nowrap;
        width: fit-content;
        .label {
          color: #232a34;
          font-weight: 700;
        }
        .span {
          color: #232a34;
          font-family: 'DM Sans';
          font-weight: 500;
        }
        .value {
          color: #717d90;
          font-family: 'DM Sans';
          font-weight: 500;
        }
      }
    }
  }

  .close {
    position: relative;
    display: flex;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }
}

.clearAll {
  all: unset;
  align-items: center;
  border-radius: 40px;
  box-sizing: border-box;
  display: inline-flex;
  cursor: pointer;
  flex: 0 0 auto;
  height: 40px;
  padding: 6px 14px;
  position: relative;
  .close {
    position: relative;
    display: flex;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
  }
  .label {
    color: #232a34;
    font-family: 'DM Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
    line-height: 1.125rem;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
}
