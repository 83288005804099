.appHeader {
  display: flex;
  height: 64px;
  background-color: #232a34;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 2rem;
  padding-right: 2rem;
  align-items: center;
  justify-content: space-between;
}
.logo {
  width: 139px;
  height: 30px;
  cursor: pointer;
}
.menu {
  display: inline-flex;
  align-items: flex-start;
  gap: 2.5rem;
  .item {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: #8993a3;
    font-family: 'DM Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.125rem;
    text-decoration: none;
    cursor: pointer;
    .icon {
      &::before {
        color: #8993a3;
      }
    }
    &:hover {
      color: #fff;

      .icon {
        &::before {
          color: #fff;
        }
      }
    }
  }
}
.right {
  display: inline-flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 1.5rem;
  .text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .content {
      color: #fff;
      font-family: 'DM Sans';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
      margin: 0;
    }
    .desc {
      color: #8993a3;
      font-family: 'DM Sans';
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1rem;
      margin: 0;
    }
  }
  .btnUpgrade,
  .btnGenerate {
    color: #fff;
    font-family: 'DM Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.125rem;

    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
    }
  }
}
