.appBreadcrumbs {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: baseline;
  li {
    margin-right: 5px;
    color: #232a34;
    font-family: 'DM Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    &:last-child {
      color: #717e90;
      margin-right: 0;
      a {
        color: #717e90;
        pointer-events: none;
      }
    }
    + li::before {
      content: ' > ';
      margin-right: 5px;
      color: #717e90;
      font-family: 'DM Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    a {
      text-decoration: none;
      transition: color 0.3s ease;
      color: #232a34;
      &:hover {
        color: #232a34;
      }
    }
  }
}
