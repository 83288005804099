.control {
  display: flex;
  align-items: center;
  .navButton {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    &:disabled {
      pointer-events: none;
    }
    &:hover {
      .icon {
        &:before {
          color: #f25844;
        }
      }
    }
  }
  .slideName {
    color: #717e90;
    font-family: 'DM Sans';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem;
    text-align: center;
    min-width: 5rem;
  }
}

.carouselBody {
  margin: 0;
  padding: 0;
  .content {
    color: #232a34;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    margin-bottom: 0.75rem;
    flex-wrap: wrap;
    word-break: break-word;
    white-space: break-spaces;
    overflow-wrap: break-word;
    p {
      margin: 0;
    }
  }
}
