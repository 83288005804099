.groupLayout {
  position: relative;
  display: flex;
  flex-grow: 1;
  max-width: 42.125rem;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1.75rem;
  flex-wrap: wrap;
}
