.resultStatus {
  display: inline-flex;
  padding: 0.25rem 1rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 2.5rem;
  .label {
    font-family: 'DM Sans';
    text-transform: capitalize;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
  &.pass {
    background: rgba(87, 174, 96, 0.1);
    color: #57ae60;
    ::before {
      color: #57ae60;
    }
  }
  &.fail {
    color: #e84f40;
    background: rgba(232, 79, 64, 0.1);
    ::before {
      color: #e84f40;
    }
  }
  &.error {
    background: rgba(242, 153, 74, 0.1);
    color: #f2994a;
    ::before {
      color: #f2994a;
    }
  }
  &.ignor {
    background: rgba(113, 126, 144, 0.1);
    color: #717e90;
    ::before {
      color: #717e90;
    }
  }
}
