.stackedBar {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border-radius: 0.25rem;
  background: #fff;
  padding: 1.5rem 1.5rem;
  flex-direction: column;
  position: relative;
  .chartbody {
    display: flex;
    position: relative;
    flex-grow: 1;
    flex-direction: column;
    :global {
      .p-chart {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    .title {
      display: flex;
      flex-grow: 1;
      color: #232a34;
      font-family: 'DM Sans';
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
      margin: 0;
      padding: 0;
    }
  }
}
