.doughnutWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 1;
  .doughnutTile {
    display: flex;
    flex-shrink: 0;
    border-radius: 0.25rem;
    background: #fff;
    flex-direction: column;
    padding: 1.5rem;
    cursor: pointer;
    transition:
      transform 0.3s ease,
      box-shadow 0.3s ease;
    will-change: transform, box-shadow;
    &:hover {
      transform: scale(1.03);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    }
    .title {
      color: #232a34;
      font-family: 'DM Sans';
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
      margin: 0;
      padding: 0;
    }
    .body {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3rem;
      &:global {
        @media screen and (max-width: 1544px) {
          gap: 1rem;
        }
      }
      .chart {
        display: flex;
        width: 10rem;
        height: 10rem;
        flex-shrink: 0;
        .skeletonCircle {
          display: flex;
          align-items: center;
          justify-items: center;
          position: relative;
          .skeletonOne {
            display: flex;
            align-items: center;
            justify-items: center;
            justify-content: center;
            flex-grow: 1;
          }
          .skeletonTwo {
            display: flex;
            align-items: center;
            justify-items: center;
          }
        }
      }
      .legend {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        .item {
          display: flex;
          align-items: center;
          gap: 1.5rem;
          justify-content: space-between;
          align-self: stretch;
          .left {
            display: flex;
            align-items: center;
            gap: 0.75rem;
            color: #717e90;
            font-family: 'DM Sans';
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.5rem;
          }
          .right {
            display: flex;
            min-width: 2.8125rem;
            height: 1.75rem;
            padding: 0.625rem 0.5rem;
            justify-content: center;
            align-items: center;
            gap: 0.625rem;
            border-radius: 2.5rem;
            background: #f5f7fa;
            color: var(--Text-Titles, #232a34);
            font-family: 'DM Sans';
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.125rem;
          }
        }
      }
    }
  }
}
