.tileLayout {
  display: flex;
  padding: 2rem 1.5rem 2.125rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 2.0625rem;
  cursor: pointer;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  will-change: transform, box-shadow;
  &:hover {
    transform: scale(1.03);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  &.tileStyle {
    border-radius: 0.25rem;
    background: #fff;
  }
  .context {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    .titleLayout {
      padding: 0;
      margin: 0;
      &.titleWithIcon {
        display: flex;
        gap: 0.75rem;
      }
      &.titleStyle {
        color: #232a34;
        font-family: 'DM Sans';
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5rem;
        .icon {
          &.green {
            &:before {
              color: #57ae60;
            }
          }
          &.orange {
            &:before {
              color: #f2994a;
            }
          }
          &.red {
            &:before {
              color: #e84f40;
            }
          }
          &.grey {
            &:before {
              color: #a8b3c3;
            }
          }
        }
      }
    }
    .count {
      display: flex;
      width: 10.125rem;
      height: 3.125rem;
      flex-direction: column;
      justify-content: center;
      z-index: 1;
      &.countStyle {
        color: var(--Text-Titles, #232a34);
        font-family: 'DM Sans';
        font-size: 3.375rem;
        font-style: normal;
        font-weight: 700;
        line-height: 4.75rem; /* 140.741% */
        &.green {
          color: #57ae60;
        }
        &.orange {
          color: #f2994a;
        }
        &.red {
          color: #e84f40;
        }
        &.grey {
          color: #a8b3c3;
        }
      }
    }
  }
  .iconBoxLayout {
    display: flex;
    width: 5rem;
    height: 5rem;
    padding: 1.25rem;
    justify-content: center;
    align-items: center;
    &.iconBoxStyle {
      border-radius: 3.75rem;
      background: var(--Secondary-Colors-Gray, #f5f7fa);
      .icon {
        &.green {
          &:before {
            color: #57ae60;
          }
        }
        &.orange {
          &:before {
            color: #f2994a;
          }
        }
        &.red {
          &:before {
            color: #e84f40;
          }
        }
        &.grey {
          &:before {
            color: #a8b3c3;
          }
        }
      }
    }
  }
}
