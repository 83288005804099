.explanationCell {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 0.91rem;
  .content {
    display: flex;
    color: #232a34;
    font-family: 'DM Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
  .button {
    display: flex;
    align-items: center;
    color: #232a34;
    font-family: 'DM Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.125rem;
    cursor: pointer;
    margin-top: 0.81rem;
    align-items: center;
    gap: 0.625rem;
    transition: color 0.3s ease;
    &.loading {
      cursor: default;
      &:hover {
        color: #232a34;
      }
    }
    &:hover {
      color: #f25844;
      .icon {
        &:before {
          color: #f25844;
        }
      }
    }
  }
}
