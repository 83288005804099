.p-tooltip {
  .p-tooltip-text {
    background: $tooltipBg;
    color: $tooltipTextColor;
    padding: $tooltipPadding;
    font-family: 'DM Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    box-shadow: 0px 0px 30px 0px rgba(35, 42, 52, 0.1);
    border-radius: $borderRadius;
  }

  &.p-tooltip-right {
    .p-tooltip-arrow {
      border-right-color: $tooltipBg;
    }
  }

  &.p-tooltip-left {
    .p-tooltip-arrow {
      border-left-color: $tooltipBg;
    }
  }

  &.p-tooltip-top {
    .p-tooltip-arrow {
      border-top-color: $tooltipBg;
    }
  }

  &.p-tooltip-bottom {
    .p-tooltip-arrow {
      border-bottom-color: $tooltipBg;
    }
  }
}
