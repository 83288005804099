@import 'variables';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?2nmljk');
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?2nmljk#iefix')
      format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?2nmljk')
      format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?2nmljk')
      format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?2nmljk##{$icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='unskriptIcon-'],
[class*=' unskriptIcon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.unskriptIcon-analyse_problem {
  &:before {
    content: $unskriptIcon-analyse_problem;
    color: #717e90;
  }
}
.unskriptIcon-copy {
  &:before {
    content: $unskriptIcon-copy;
    color: #717e90;
  }
}
.unskriptIcon-details {
  &:before {
    content: $unskriptIcon-details;
    color: #717e90;
  }
}
.unskriptIcon-execute {
  &:before {
    content: $unskriptIcon-execute;
    color: #717e90;
  }
}
.unskriptIcon-iteration {
  &:before {
    content: $unskriptIcon-iteration;
    color: #717e90;
  }
}
.unskriptIcon-mask {
  &:before {
    content: $unskriptIcon-mask;
    color: #717e90;
  }
}
.unskriptIcon-plus {
  &:before {
    content: $unskriptIcon-plus;
    color: #717e90;
  }
}
.unskriptIcon-possible-diagnostics {
  &:before {
    content: $unskriptIcon-possible-diagnostics;
    color: #717e90;
  }
}
.unskriptIcon-possible-fixes {
  &:before {
    content: $unskriptIcon-possible-fixes;
    color: #717e90;
  }
}
.unskriptIcon-progress_activity {
  &:before {
    content: $unskriptIcon-progress_activity;
    color: #717e90;
  }
}
.unskriptIcon-re-run-check {
  &:before {
    content: $unskriptIcon-re-run-check;
    color: #717e90;
  }
}
.unskriptIcon-reset {
  &:before {
    content: $unskriptIcon-reset;
    color: #717e90;
  }
}
.unskriptIcon-revert {
  &:before {
    content: $unskriptIcon-revert;
    color: #717e90;
  }
}
.unskriptIcon-send_a_message {
  &:before {
    content: $unskriptIcon-send_a_message;
    color: #717e90;
  }
}
.unskriptIcon-sets-of-recommendations {
  &:before {
    content: $unskriptIcon-sets-of-recommendations;
    color: #717e90;
  }
}
.unskriptIcon-AI {
  &:before {
    content: $unskriptIcon-AI;
    color: #717e90;
  }
}
.unskriptIcon-calendar {
  &:before {
    content: $unskriptIcon-calendar;
    color: #717e90;
  }
}
.unskriptIcon-chart {
  &:before {
    content: $unskriptIcon-chart;
    color: #717e90;
  }
}
.unskriptIcon-check {
  &:before {
    content: $unskriptIcon-check;
    color: #717e90;
  }
}
.unskriptIcon-checks {
  &:before {
    content: $unskriptIcon-checks;
    color: #717e90;
  }
}
.unskriptIcon-chevron_left {
  &:before {
    content: $unskriptIcon-chevron_left;
    color: #717e90;
  }
}
.unskriptIcon-chevron_right {
  &:before {
    content: $unskriptIcon-chevron_right;
    color: #717e90;
  }
}
.unskriptIcon-close {
  &:before {
    content: $unskriptIcon-close;
    color: #717e90;
  }
}
.unskriptIcon-error {
  &:before {
    content: $unskriptIcon-error;
    color: #717e90;
  }
}
.unskriptIcon-expand_less {
  &:before {
    content: $unskriptIcon-expand_less;
    color: #717e90;
  }
}
.unskriptIcon-expand_more {
  &:before {
    content: $unskriptIcon-expand_more;
    color: #717e90;
  }
}
.unskriptIcon-fail {
  &:before {
    content: $unskriptIcon-fail;
    color: #717e90;
  }
}
.unskriptIcon-filter {
  &:before {
    content: $unskriptIcon-filter;
    color: #717e90;
  }
}
.unskriptIcon-ignore {
  &:before {
    content: $unskriptIcon-ignore;
    color: #717e90;
  }
}
.unskriptIcon-minus {
  &:before {
    content: $unskriptIcon-minus;
    color: #717e90;
  }
}
.unskriptIcon-pass {
  &:before {
    content: $unskriptIcon-pass;
    color: #717e90;
  }
}
.unskriptIcon-report {
  &:before {
    content: $unskriptIcon-report;
    color: #717e90;
  }
}
.unskriptIcon-search {
  &:before {
    content: $unskriptIcon-search;
    color: #717e90;
  }
}
.unskriptIcon-sorting {
  &:before {
    content: $unskriptIcon-sorting;
    color: #717e90;
  }
}
.unskriptIcon-sorting_down {
  &:before {
    content: $unskriptIcon-sorting_down;
    color: #717e90;
  }
}
.unskriptIcon-sorting_up {
  &:before {
    content: $unskriptIcon-sorting_up;
    color: #717e90;
  }
}
.unskriptIcon-arrow_circle_up {
  &:before {
    content: $unskriptIcon-arrow_circle_up;
    color: #717e90;
  }
}
.unskriptIcon-DevRev {
  &:before {
    content: $unskriptIcon-DevRev;
    color: #000000;
  }
}
.unskriptIcon-Jira {
  &:before {
    content: $unskriptIcon-Jira;
    color: #2684ff;
  }
}
.unskriptIcon-DevRev_multicolor {
  &:before {
    content: $unskriptIcon-DevRev-e927; 
  }

  &.variant-e927 {
    &:before {
      content: $unskriptIcon-DevRev-e927;
      color: #717E90;
    }
  }

  &.variant-e928 {
    &:before {
      content: $unskriptIcon-DevRev-e928;
      color: #000000;
    }
  }

  &.variant-e929 {
    &:before {
      content: $unskriptIcon-DevRev-e929;
      color: #000000;
    }
  }

  &.variant-e92a {
    &:before {
      content: $unskriptIcon-DevRev-e92a;
      color: #000000;
    }
  }
}