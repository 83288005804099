.profile {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  .avatar {
    border-radius: 2.375rem;
    width: 2.375rem;
    background: #8993a3;
    height: 2.375rem;
    flex-shrink: 0;
  }
  .name {
    display: flex;
    height: 1.375rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    color: #8993a3;
    font-family: 'DM Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    margin-left: 0.75rem;
    flex-wrap: nowrap;
    flex-direction: row;
    cursor: pointer;
    span {
      display: flex;
      align-items: center;
    }

    &:hover {
      color: #f25844;
      .dropdown {
        align-items: center;
        &::before {
          color: #f25844;
        }
      }
    }
  }
}
