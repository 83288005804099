.showMoreBtn {
  display: inline-flex;
  align-items: center;
  gap: 0.625rem;
  color: #232a34;
  font-size: 14px;
  font-family: DM Sans;
  font-weight: 700;
  line-height: 18px;
  word-wrap: break-word;
  cursor: pointer;
  justify-content: center;
  margin: 1.69rem 0;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  will-change: transform, box-shadow;
  &:hover {
    transform: scale(1.06);
  }
  .icon {
    ::before {
      color: #232a34;
    }
  }
}
