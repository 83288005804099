.dataHeader {
  display: flex;
  flex-grow: 1;
  position: relative;
  color: var(--Text-Titles, #232a34);
  font-family: 'DM Sans';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem; /* 133.333% */
  margin-bottom: 0.5rem;

  &.marginStyles {
    .headerItemCheck {
      display: flex;
      flex-grow: 1;
      margin-left: 2.5rem;
    }

    .headerItemStatus {
      display: flex;
      margin-right: 3.4rem;
    }

    .headerItemAction {
      display: flex;
      margin-right: 3.4rem;
    }
  }

  &.paddingStyles {
    .headerItemCheck {
      display: flex;
      flex-grow: 1;
      padding-left: 0.8rem;
    }

    .headerItemStatus {
      display: flex;
      width: 6rem;
      padding-left: 1.2rem;
    }

    .headerItemAction {
      display: flex;
      padding-right: 1.2rem;
    }
  }
}
