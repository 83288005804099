
.actionButtonContainer {
    position: relative;
    display: inline-block; /* Ensure proper positioning */
    margin-left: 1rem;
  
    .tooltipText {
      visibility: hidden;
      width: 9.5rem;
      background-color: #ffffff;
      color: #232a34;
      font-family: 'DM Sans';
      font-style: 'normal';
      font-weight: 400;
      font-size: 0.875rem;
      text-align: center;
      border-radius: 5px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      top: 125%; /* Position below the button */
      margin-left: -110px;
      opacity: 0;
      transition: opacity 0.3s;
    }
  
    &:hover .tooltipText {
      visibility: visible; /* Show tooltip */
      opacity: 1; /* Fade in */
    }
  
    &:hover .actionButton {
      border: 1px solid #d9e2ef;
    }
  
    .actionButton {
      background-color: #f7f9fc;
      border: none;
      border-radius: 0.25rem;
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      outline: none;
      transition:
        background-color 0.3s,
        color 0.3s;
  
      .devRevIconContainer {
        position: absolute;
        width: 1.1rem;
        height: 1.1rem;
        visibility: hidden;
        opacity: 0;
        transition:
          visibility 0s,
          opacity 0.3s linear;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
  
      .devRevIcon {
        position: absolute;
        left: -0.05rem;
      }
  
      &:hover .devRevIconContainer {
        visibility: visible;
        opacity: 1;
      }
  
      &:hover .icon_devrev {
        visibility: hidden;
        opacity: 0;
        transition:
          visibility 0s,
          opacity 0.3s linear;
      }
    }
  }