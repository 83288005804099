.statusContainer {
  display: flex;
  align-items: center;
}
.onlineStatusText {
  margin-left: 0.5rem;
  margin-right: 1.5rem;
  font-size: 1rem;
  color: #57ae60;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}
.offlineStatusText {
  margin-left: 0.5rem;
  margin-right: 1.5rem;
  font-size: 1rem;
  color: #717e90;
  font-family: 'DM Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
}
