.dropdownWrapper {
  width: 100%;
  overflow: 'hidden';
  padding-bottom: 2rem;
}

.dropdownButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: none;
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  color: #232a34;
  background: white;
  width: 100%;
  box-sizing: border-box;
  height: 2.5rem;
}

.dropdownIcon {
  margin-right: 1.2rem;
}

.dropdownContent {
  max-height: 25.75rem;
  overflow: 'hidden';
  background-color: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.itemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.125rem;
}

.name {
  margin: 0;
  padding: 0;
  font-family: 'DM Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  color: #717e90;
  margin-left: 1rem;
}

.item {
  display: flex;
  min-width: 2.875rem;
  align-items: center;
  gap: 0.25rem;
  color: #232a34;
  font-family: 'DM Sans';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  .icon {
    &.pass {
      color: #57ae60;
      &:before {
        color: #57ae60;
      }
    }
    &.fail {
      color: #e84f40;
      &:before {
        color: #e84f40;
      }
    }
    &.error {
      color: #f2994a;
      &:before {
        color: #f2994a;
      }
    }
    &.arrow {
      color: #717e90;
      &:before {
        color: #717e90;
      }
    }
  }
}

.itemList {
  flex-grow: 1;
  &.link {
    &:hover {
      .moveUpItem {
        cursor: pointer;
        pointer-events: auto;
      }
    }
  }

  &.even {
    background: #f5f7fa; // Light background for even items
  }

  .data {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }
}

.moveUpItem {
  display: flex;
  min-width: 2.875rem;
  align-items: center;
  gap: 0.25rem;
  color: #232a34;
  font-family: 'DM Sans';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;

  .arrowContainer {
    position: relative;
    display: inline-block; /* Ensure proper positioning */

    .tooltipText {
      visibility: hidden;
      width: 4.5rem;
      background-color: #ffffff;
      color: #232a34;
      text-align: center;
      border-radius: 5px;
      padding: 5px;
      position: absolute;
      z-index: 1;
      top: 125%; /* Position below the button */
      margin-left: -35px;
      opacity: 0;
      transition: opacity 0.3s;
    }

    &:hover .tooltipText {
      visibility: visible; /* Show tooltip */
      opacity: 1; /* Fade in */
    }

    .moveupButton {
      background-color: #f7f9fc;
      border: 1px solid #d9e2ef;
      border-radius: 0.25rem;
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      outline: none;
      transition:
        background-color 0.3s,
        color 0.3s;
    }

    &:hover .tooltip {
      visibility: visible; // Show tooltip on hover
      opacity: 1; // Fade in
    }
  }

  .loadingContent {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    &.arrow {
      color: #717e90;
      opacity: 0;
      transition: opacity 0.7s ease;
      animation: fadeIn 0.7s forwards;
    }

    &.fade {
      opacity: 1;
    }
  }
}

// Keyframe for the fade-in effect
@keyframes fadeIn {
  from {
    opacity: 0; // Start fully transparent
  }
  to {
    opacity: 1; // End fully visible
  }
}
