.checkSummary {
  display: flex;
  gap: 1.75rem;
  flex-grow: 1;
  .charts {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 56.75rem;
    gap: 1.88rem;
    .tiles {
      display: flex;
      flex-direction: row;
    }
    .doughnuts {
      display: flex;
      gap: 1.75rem;
      .legendLabel {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        min-width: 6rem;
        .colorBox {
          display: flex;
        }
      }
      .doughnutTileWrapper {
        flex-grow: 1;
        width: 48%;

        &:global {
          @media screen and (max-width: 1544px) {
            width: auto;
          }
        }
        .tile {
          flex-grow: 1;
        }
      }
    }
    .checkEvolution {
      display: flex;
      flex-grow: 1;
    }
  }
}

.date {
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-end;

  .input {
    color: #717e90;
    font-family: 'DM Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    padding: 0 0.5rem;

    &::placeholder {
      color: #717e90;
      font-family: 'DM Sans';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }
  }
  :global {
    .p-inputtext.p-component.p-inputtext {
      border: none;
    }
    .p-calendar.p-inputwrapper.p-calendar-w-btn.p-calendar-w-btn-right {
      border: 1px solid #ccd8ea;
      transition:
        background-color 0.2s,
        color 0.2s,
        border-color 0.2s,
        box-shadow 0.2s;
      appearance: none;
      border-radius: 0.25rem;
    }
    .p-datepicker-trigger.p-button.p-button-icon-only {
      background-color: transparent;
      background: transparent;
      border: none;
    }
  }
}

.createCluster {
  margin-left: 1.5rem;
  font-family: 'DM Sans';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem;
  padding: 0 0.75rem;
  width: 15.75rem;
  justify-content: center;
  align-items: center;
  background-color: #f25844;
}
.createCluster:hover {
  background-color: #d74a3a;
  opacity: 1;
}

.dialog {
  width: 29.25rem;
  height: 19.275rem;
  border-radius: 0.25rem 0 0 0;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  width: '100%';
  height: 2rem;
  margin-top: 0.2rem;
  padding-left: 0.6rem;
}

.label {
  font-family: 'DM Sans', sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
  align-self: flex-start;
}

.header {
  margin-bottom: 1rem;
  padding-top: 1.5rem;
  font-family: 'DM Sans';
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.875rem;
  padding-left: 0.5rem;
  text-align: left;
}

.footerContainer {
  margin-bottom: 0.6rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 0.6rem;
  height: 3.125rem;
}

.inputText {
  height: 2.5rem;
  width: 24.5rem;
  border-radius: 0.25rem;
  background-color: white;
  border-width: 0.063rem;
  padding-left: 0.45rem;
  border-color: #ccd8ea;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem;
  font-weight: 400;
  font-size: 0.875rem;
  font-family: 'DM Sans';
  font-style: normal;
}
.inputText::placeholder {
  color: #717e90;
  opacity: 1;
}

.createButton {
  height: 100%;
  width: 4.938rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  font-family: 'DM Sans';
  background-color: #f25844;
  transition: background-color 0.3s;
}
.createButton:hover {
  background-color: #d74a3a;
  opacity: 1;
}
.cancelButton {
  height: 100%;
  width: 4.938rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  color: #232a34;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  font-family: 'DM Sans';
  cursor: pointer;
}

.error {
  color: #f25844;
  margin-top: 0.25rem;
  line-height: 1rem;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: normal;
  font-family: 'DM Sans';
}

.disabledButton {
  background-color: #ece8e8;
  color: #232a34;
  cursor: not-allowed;
  border: 1px solid #d0d0d0;
  opacity: 0.6;
  pointer-events: none;
}

.disabledButton:hover {
  background-color: #7a7878;
  opacity: 1;
}

.loadingContent {
  display: flex;
  align-items: center;
}
