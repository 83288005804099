.p-skeleton {
  background-color: $skeletonBg;
  border-radius: $borderRadius;
  color: $skeletonBg;

  &:after {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      $skeletonAnimationBg,
      rgba(255, 255, 255, 0)
    );
  }
}
