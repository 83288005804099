@use 'sass:math';

// core
.p-multiselect {
  display: inline-flex;
  cursor: pointer;
  user-select: none;
}

.p-multiselect-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.p-multiselect-label-container {
  overflow: hidden;
  flex: 1 1 auto;
  cursor: pointer;
}

.p-multiselect-label {
  display: block;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-multiselect-label-empty {
  overflow: hidden;
  visibility: hidden;
}

.p-multiselect-token {
  cursor: default;
  display: inline-flex;
  align-items: center;
  flex: 0 0 auto;
}

.p-multiselect-token-icon {
  cursor: pointer;
}

.p-multiselect .p-multiselect-panel {
  min-width: 100%;
}

.p-multiselect-items-wrapper {
  overflow: auto;
}

.p-multiselect-items {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.p-multiselect-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: normal;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

.p-multiselect-item-group {
  cursor: auto;
}

.p-multiselect-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.p-multiselect-filter-container {
  position: relative;
  flex: 1 1 auto;
}

.p-multiselect-filter-icon {
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
}

.p-multiselect-filter-container .p-inputtext {
  width: 100%;
}

.p-multiselect-close {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  margin-left: auto;
}

.p-fluid .p-multiselect {
  display: flex;
}

// theme
.p-multiselect {
  background: $white100;
  border: $inputBorder;
  transition: $formElementTransition;
  border-radius: $borderRadius;
  outline-color: transparent;

  &:not(.p-disabled):hover {
    border-color: $inputHoverBorderColor;
  }

  &:not(.p-disabled).p-focus {
    @include focused-input();
  }

  &.p-variant-filled {
    background: $inputFilledBg;

    &:not(.p-disabled):hover {
      background-color: $inputFilledHoverBg;
    }

    &:not(.p-disabled).p-focus {
      background-color: $inputFilledFocusBg;
    }
  }

  .p-multiselect-label {
    padding: $inputPadding;
    transition: $formElementTransition;

    &.p-placeholder {
      color: $inputPlaceholderTextColor;
    }
  }

  &.p-multiselect-chip {
    .p-multiselect-token {
      padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
      margin-right: $inlineSpacing;
      background: $chipBg;
      color: $chipTextColor;
      border-radius: $chipBorderRadius;

      .p-multiselect-token-icon {
        margin-left: $inlineSpacing;
      }
    }
  }

  .p-multiselect-trigger {
    background: transparent;
    color: $inputIconColor;
    width: $inputGroupAddOnMinWidth;
    border-top-right-radius: $borderRadius;
    border-bottom-right-radius: $borderRadius;
  }

  &.p-invalid.p-component {
    @include invalid-input();
  }
}

.p-inputwrapper-filled {
  &.p-multiselect {
    &.p-multiselect-chip {
      .p-multiselect-label {
        padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
      }
    }
  }
}

.p-multiselect-panel {
  background: $inputOverlayBg;
  color: $inputListTextColor;
  border: $inputOverlayBorder;
  border-radius: $borderRadius;
  box-shadow: $inputOverlayShadow;

  .p-multiselect-header {
    padding: $inputListHeaderPadding;
    border-bottom: $inputListHeaderBorder;
    color: $inputListHeaderTextColor;
    background: $inputOverlayHeaderBg;
    margin: $inputListHeaderMargin;
    border-top-right-radius: $borderRadius;
    border-top-left-radius: $borderRadius;

    .p-multiselect-filter-container {
      .p-inputtext {
        padding-right: nth($inputPadding, 2) + $primeIconFontSize;
      }

      .p-multiselect-filter-icon {
        right: nth($inputPadding, 2);
        color: $inputIconColor;
      }
    }

    .p-checkbox {
      margin-right: $inlineSpacing;
    }

    .p-multiselect-close {
      margin-left: $inlineSpacing;
      @include action-icon();
    }
  }

  .p-multiselect-items {
    padding: $inputListPadding;

    .p-multiselect-item {
      margin: $inputListItemMargin;
      padding: $inputListItemPadding;
      border: $inputListItemBorder;
      color: $inputListItemTextColor;
      background: $inputListItemBg;
      transition: $listItemTransition;
      border-radius: $inputListItemBorderRadius;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.p-highlight {
        color: $highlightTextColor;
        background: $highlightBg;

        &.p-focus {
          background: $highlightFocusBg;
        }
      }

      &:not(.p-highlight):not(.p-disabled) {
        &.p-focus {
          color: $inputListItemTextHoverColor;
          background: $inputListItemHoverBg;
        }
      }

      .p-checkbox {
        margin-right: $inlineSpacing;
      }
    }

    .p-multiselect-item-group {
      margin: $submenuHeaderMargin;
      padding: $submenuHeaderPadding;
      color: $submenuHeaderTextColor;
      background: $submenuHeaderBg;
      font-weight: $submenuHeaderFontWeight;
    }

    .p-multiselect-empty-message {
      padding: $inputListItemPadding;
      color: $inputListItemTextColor;
      background: $inputListItemBg;
    }
  }
}
