.dataListView {
  display: flex;
  min-width: 27.5rem;
  flex-direction: column;
  flex-shrink: 0;
  border-radius: 0.25rem;
  background: #fff;
  padding: 1.5rem;
  position: relative;
  flex-grow: 1;
  .headerFilter {
    display: flex;
    margin-top: 0;
    margin-bottom: 1rem;
    > div {
      margin-top: -10px;
    }
  }
  .header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 2rem;

    .title {
      display: flex;
      color: #232a34;
      font-family: 'DM Sans';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.5rem;
      margin: 0;
      cursor: pointer;
      height: 2.5rem;
      align-items: center;
      text-align: center;
      justify-content: center;
      // flex-grow: 1;
    }
    .active {
      background-color: #f5f7fa; /* Background color for the active title */
    }
    .sortBy {
      display: flex;
      align-items: center;
      gap: 1rem;
      flex-grow: 1;

      .label {
        color: var(--Text-Titles, #232a34);
        text-align: right;
        font-family: 'DM Sans';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.125rem;
      }
    }
  }

  .viewListBox {
    display: flex;
    flex-grow: 1;
    width: 100%;
    position: relative;
    .headerItems {
      display: flex;
      flex-grow: 1;
      width: 100%;
      position: relative;
    }
    .loading {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
    }
    .empty {
      display: flex;
      flex-direction: column;
      height: 100%;
      color: #717e90;
      font-family: 'DM Sans';
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }
    :global {
      .p-component-overlay {
        background-color: transparent;
      }
    }
  }
  .viewBy {
    border: #ccd8ea;
    display: flex;
    flex-direction: row;
    border-radius: 0.25rem;
    width: 100%;
    margin-bottom: 1rem;
    &.open {
      border: 1px solid #f25844;
    }
    > div {
      display: flex;
      flex-flow: 1;
      width: 100%;
    }
  }
  .list {
    flex-direction: column;
    .itemList {
      display: flex;
      flex-grow: 1;
      padding: 0.625rem 1rem;
      justify-content: space-between;
      align-items: center;
      flex-shrink: 0;
      width: 100%;
      border-radius: 0.25rem;
      &.link {
        cursor: pointer;
        &:hover {
          .name {
            color: #f25844;
          }
        }
      }
      &.even {
        background: #f5f7fa;
      }
    }
    .itemList {
      display: flex;
      flex-grow: 1;
      .data {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        .item {
          display: flex;
          min-width: 2.875rem;
          align-items: center;
          gap: 0.25rem;
          color: #232a34;
          font-family: 'DM Sans';
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.5rem;
          .icon {
            &.pass {
              color: #57ae60;
              &:before {
                color: #57ae60;
              }
            }
            &.fail {
              color: #e84f40;
              &:before {
                color: #e84f40;
              }
            }
            &.error {
              color: #f2994a;
              &:before {
                color: #f2994a;
              }
            }
          }
        }
      }

      .titles {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        padding: 0 10px 0 0;
      }
      .name {
        overflow-wrap: anywhere;
      }
      .name,
      .checkName {
        display: flex;
        flex-grow: 1;
        color: #232a34;
        font-family: 'DM Sans';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 700;
        padding: 0 1rem 0 0;
        line-height: 1.125rem; /* 128.571% */
        margin: 0;
      }
      .checkName {
        font-weight: 400;
      }
    }
  }
}

.date {
  display: flex;
  flex-grow: 1;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  .input {
    color: #717e90;
    font-family: 'DM Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
    padding: 0 0.5rem;

    &::placeholder {
      color: #717e90;
      font-family: 'DM Sans';
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5rem;
    }
  }
  :global {
    .p-inputtext.p-component.p-inputtext {
      border: none;
    }
    .p-calendar.p-inputwrapper.p-calendar-w-btn.p-calendar-w-btn-right {
      border: 1px solid #ccd8ea;
      transition:
        background-color 0.2s,
        color 0.2s,
        border-color 0.2s,
        box-shadow 0.2s;
      appearance: none;
      border-radius: 0.25rem;
    }
    .p-datepicker-trigger.p-button.p-button-icon-only {
      background-color: transparent;
      background: transparent;
      border: none;
    }
  }
}

.dataHeader {
  display: flex;
  flex-grow: 1;
  position: relative;
  color: var(--Text-Titles, #232a34);
  font-family: 'DM Sans';
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem; /* 133.333% */
  margin-bottom: 0.5rem;

  & .headerItemCheck {
    display: flex;
    flex-grow: 1;
    margin-left: 2.5rem;
  }

  & .headerItemStatus {
    display: flex;
    margin-right: 3.4rem;
  }
}

.clusterConfigDropdownContainer {
  border: #ccd8ea;
  margin-right: 1rem;
  border-radius: 0.25rem;
  width: 15.6rem;
  margin-right: 1rem;
  &.open {
    border: 1px solid #f25844;
  }
}

.clusterDetailsDropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #ccd8ea;
  background-color: #eaeff5;
}

.clusterConfigDropdown {
  display: flex;
  height: 2.6rem;
  padding-left: 0.5rem;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.1rem;
  border-color: #ccd8ea;
  background-color: #eaeff5;
}

.outerContainer {
  display: flex;
  align-items: center;
}

.loadingContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.okayButton {
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  font-family: 'DM Sans';
  background-color: #f25844;
  transition: background-color 0.3s;
}

.okayButton:hover {
  background-color: #d74a3a;
  opacity: 1;
}

.cancelButton {
  background: transparent;
  border: none;
  color: #232a34;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  font-family: 'DM Sans';
  cursor: pointer;
}

.favoriteContainer {
  display: flex;
  align-items: center;

  .favoriteText {
    margin-left: 0.5rem;
    width: 4.5rem;
    font-family: 'DM Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
  }
  .favoriteSwitch {
    height: 1.5rem;
    cursor: pointer;
  }
  .disabledText {
    color: #b0b0b0;
    cursor: default;
  }
}
