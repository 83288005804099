.filterColumnItem {
  display: flex;
  align-items: center;
  .chbox {
    margin-right: 0.5rem;
  }
}

.clikedRow {
  &:hover {
    background-color: #e84f401c;
  }
}

.clearAllBtn {
  display: flex;
  flex-grow: 1;
  padding: 0.25rem 1rem;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 0rem 0rem 0.25rem 0.25rem;
  border-top: 1px solid #dfe7f3;
  cursor: pointer;
  .label {
    display: flex;
    height: 2.5rem;
    cursor: pointer;
    padding: 0.375rem 0.875rem;
    align-items: center;
    color: #232a34;
    font-family: 'DM Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.125rem;
  }
}
