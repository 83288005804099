.charts {
  position: relative;
  display: flex;
  flex-grow: 1;
  padding: 1.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.3125rem;
  &.chartsStyle {
    border-radius: 0.25rem;
    background: #fff;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    .title {
      display: flex;
      margin: 0;
      .titleStyle {
        color: #232a34;
        font-family: 'DM Sans';
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 700;
        line-height: 1.5rem;
      }
    }

    .tabs {
      display: flex;
      .tabLayout {
        display: flex;
        height: 2.5rem;
        padding: 0.375rem 0.875rem;
        align-items: center;
        cursor: pointer;
        margin-left: 0.2rem;
        margin-right: 0.2rem;
        gap: 0.605rem;
        &.tabStyle {
          border-radius: 0.25rem;
          background: #fff;

          &.active {
            background-color: #f5f7fa;
            transition: background-color 0.3s ease;
          }
          transition:
            transform 0.3s ease,
            box-shadow 0.3s ease;
          will-change: transform, box-shadow;
          &:hover {
            transform: scale(1.06);
            box-shadow: 0 0 4px #f5f7fa;
          }
        }
      }
    }
  }
  .body {
    display: flex;
    flex-grow: 1;
    width: 100%;
    .skeletonTicks {
      display: flex;
      flex-direction: row;
      position: absolute;
      bottom: 1.5%;
      gap: 3.35rem;
      right: 3%;
    }
  }
}

.chart {
  display: flex;
  flex-grow: 1;
  width: 100%;
  &.withSkeleton {
    width: 80%;
    margin-left: 20%;
  }
}

.skeletonBarChart {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: absolute;
  top: 4.25rem;
  left: 2rem;
  right: 3rem;
  bottom: 0;
  gap: 0.5rem;
  .sklBarRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.99rem;
  }
}
