.errorPage {
  display: flex;
  align-items: center;
  justify-content: center;
  .title {
    color: #232a34;
    font-family: 'DM Sans';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }
  .desc {
    color: #717e90;
    text-align: center;
    font-family: 'DM Sans';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
.errorLogo {
  width: 50%;
}
