.loadingDots {
  text-align: center;
  .loadingDot {
    display: inline-block;
    margin-right: 5px;
    opacity: 0;
    animation: running-dot 1.5s infinite;
    &:nth-child(1) {
      animation-delay: 0.2s;
    }
    &:nth-child(2) {
      animation-delay: 0.4s;
    }
    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }
}

@keyframes running-dot {
  0% {
    transform: translateX(-2px);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translateX(5px);
    opacity: 0;
  }
}
