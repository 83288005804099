.groupLayout {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  align-content: flex-start;
  gap: 1.75rem;
  > div {
    display: flex;
    flex: 1;
  }
  .tile {
    align-items: center;
    justify-content: center;
    .context {
      align-items: center;
      justify-content: center;
    }
    .count {
      align-items: center;
      justify-content: center;
    }
  }
}
