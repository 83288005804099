.search {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  .inputWrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    position: relative;
    .icon {
      position: absolute;
      right: 0.6rem;
      top: 0.4rem;
    }
    > input {
      display: flex;
      flex-grow: 1;
      width: 100%;
      padding-right: 2.5rem;
    }
  }
  .empty {
    display: flex;
    flex-grow: 1;
    width: 100%;
    position: relative;
    .text {
      font-size: 0.87rem;
      display: flex;
      position: absolute;
    }
  }
}
