.severity {
  display: inline-flex;
  padding: 0.25rem 1rem;
  align-items: center;
  gap: 0.5rem;
  border-radius: 2.5rem;
  .label {
    font-family: 'DM Sans';
    text-transform: capitalize;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem;
  }
  &.critical {
    background: rgba(232, 79, 64, 0.1);
    color: #e84f40;
    ::before {
      color: #e84f40;
    }
  }
  &.severe {
    color: #f2994a;
    background: rgba(242, 153, 74, 0.1);
    ::before {
      color: #f2994a;
    }
  }
  &.high {
    background: rgba(242, 201, 76, 0.1);
    color: #f2c94c;
    ::before {
      color: #f2c94c;
    }
  }
  &.medium {
    background: rgba(45, 156, 219, 0.1);
    color: #2d9cdb;
    ::before {
      color: #2d9cdb;
    }
  }
  &.low {
    background: rgba(87, 174, 96, 0.1);
    color: #57ae60;
    ::before {
      color: #57ae60;
    }
  }
}
